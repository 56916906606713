import React from "react"
//import data from "../yourdata"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <p>Copyright &copy; 2021 RippledWater.com - All Rights Reseved.</p>{" "}
      </footer>
    </>
  )
}

export default Footer
